<template>
  <form class="form-cms" @submit.prevent="addRecipeIngredient">
    <div class="form-control mt-1">
      <label for="name-ingredient-recipe">Ingrediente:</label>

      <v-select
        :options="ingredients"
        label="nombre"
        v-model="currentAddIngredient"
        placeholder="Nombre de ingrediente"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No se encontró el ingrediente: {{ search }}
          </template>
        </template>
      </v-select>
    </div>
    <div class="fields-flex">
      <div class="form-control">
        <label for="unit-ingredient-recipe">Unidad:</label>
        <p
          class="text-secondary"
          v-if="currentAddIngredientUnits.length === 0 && !isLoadingAddUnits"
        >
          Seleccione un ingrediente
        </p>
        <p class="text-secondary" v-if="isLoadingAddUnits">
          Actualizando unidades..
        </p>
        <select
          name="unit-ingredient-recipe"
          v-model="addIngredient.unit"
          v-if="currentAddIngredientUnits.length > 0 && !isLoadingAddUnits"
        >
          <option value="" disabled>Seleccione una unidad</option>
          <option
            v-for="(unit, index) in currentAddIngredientUnits"
            :value="unit.medidaAmigable"
            :key="index"
          >
            {{ unit.medidaAmigable }}
          </option>
        </select>
      </div>
      <div class="form-control">
        <label for="cant-ingredient-recipe">Cantidad:</label>
        <input
          type="number"
          name="cant-ingredient-recipe"
          placeholder="Cantidad"
          step="0.01"
          v-model="addIngredient.amount"
        />
      </div>
    </div>

    <Message
      v-if="addIngredientShowMsg"
      :message="addIngredientMsg"
      :messageType="addIngredientMsgStatus"
    />

    <div class="buttons">
      <button
        class="btn btn-primary"
        type="submit"
        :disabled="isLoadingAddIngredient"
      >
        <template v-if="!isLoadingAddIngredient">
          <i class="fas fa-plus"></i> Agregar
        </template>
        <template v-else>Agregando ...</template>
      </button>
    </div>
  </form>
</template>
<script>
import { defineAsyncComponent } from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import useRecipeIngredients from '@/modules/cms/composables/recipes/useRecipeIngredients';

export default {
  components: {
    'v-select': vSelect,
    Message: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Message" */ '@/components/Message.vue')
    ),
  },
  setup() {
    const {
      ingredients,
      isLoadingAddIngredient,
      addRecipeIngredient,
      currentAddIngredient,
      currentAddIngredientUnits,
      addIngredient,
      isLoadingAddUnits,
      addIngredientShowMsg,
      addIngredientMsg,
      addIngredientMsgStatus,
    } = useRecipeIngredients();

    return {
      ingredients,
      isLoadingAddIngredient,
      addRecipeIngredient,
      currentAddIngredient,
      currentAddIngredientUnits,
      addIngredient,
      isLoadingAddUnits,
      addIngredientShowMsg,
      addIngredientMsg,
      addIngredientMsgStatus,
    };
  },
};
</script>
